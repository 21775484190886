class Logger {
    constructor() {
        this.hello();

        if (window && window.localStorage && window.localStorage.logging !== 'on') {
            this.disableLogs();
        }
    }

    hello() {
        window.console.log('Hi there, nice to meet you!\n' +
            'Do you have an interest in development and enjoy writing good code? Join Crytek,\n' +
            'and become part of a team that\'s bringing a brighter future to gamers and developers\n' +
            'all around the world.\n\n' +

            'Visit https://www.crytek.com/career/studio/frankfurt#jobs-listing to learn more about\n' +
            'our current job openings.\n\n' +
            '---\n');
    }

    disableLogs() {
        // map JS alert() to console. prompt and confirm cannot be mapped because of phpBB
        window.alert = window.console.warn;

        for (const prop  in window.console) {
            try {
                if (typeof (window.console[prop as keyof Console]) === 'function') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    window.console[prop as keyof Console] = function () {
                    };
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
}

const ConsoleLogs = new Logger();

export default ConsoleLogs;
