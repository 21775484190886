import LazyLoad from 'vanilla-lazyload';
import isMobile from '@utils/isMobile';

import '@root/Polyfill/navigator.connection';

const lazy = new LazyLoad({
    elements_selector: '[data-bind="lazy-img"]',
    class_loaded: 'is-loaded',
    use_native: true,
});

if (
    navigator?.connection.type !== 'cellular'
    || navigator?.connection.effectiveType === '4g'
    || (navigator?.connection.type === 'cellular' && !isMobile())
) {
    new LazyLoad({
        elements_selector: '[data-bind="lazy-video"]',
        class_loaded: 'is-loaded',
    });
}

export default lazy;

